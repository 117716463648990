import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 首页综合页
  {
    path: '/',
    redirect: {name: 'index'}
  },
  {
    path: '/index',
    name: 'index',
    component: index => require(['@/views/index.vue'], index),

  },
  {
    path: '/declare',
    name: 'declare',
    component: index => require(['@/views/declare/index.vue'], index),
  }


]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
